import React from "react"

import "../styles/components/Certifications.styl"

import ance from "../images/certifications-logos/ance-logo.svg"
import ema from "../images/certifications-logos/ema-logo.svg"

import nom from "../images/certifications-logos/nom-logo.svg"
import sa from "../images/certifications-logos/sa-logo.svg"
import ul from "../images/certifications-logos/ul-logo.svg"
import { Parallax } from "react-scroll-parallax"
import { StaticImage } from "gatsby-plugin-image"

export default function Certifications() {
  return (
    <section className="Certifications">
      <h2 className="certifications__title">
        <Parallax x={["100px", "0px"]}>
          Nuestros productos cuentan con las siguientes certificaciones
        </Parallax>
      </h2>
      <div
        className="certifications__logos"
        data-sal="slide-up"
        data-sal-delay="100"
        data-sal-easing="ease"
      >
        <div className="">
          <img src={ance} className="logo" alt="ance logo" />
        </div>
        <div className="">
          <img src={ema} className="logo" alt="logo" />
        </div>
        <div className="">
          <StaticImage
            src="../images/certifications-logos/fide-logo.png"
            alt="word fide"
            className="logo"
            objectFit="contain"
          />
        </div>
        <div className="">
          <StaticImage
            src="../images/certifications-logos/intertrade-logo.png"
            alt="word fide"
            className="logo"
            objectFit="contain"
          />
        </div>
        <div className="">
          <img src={nom} className="logo" alt="logo" />
        </div>
        <div className="">
          <StaticImage
            src="../images/certifications-logos/normex-logo.png"
            alt="word normex"
            className="logo"
            objectFit="contain"
          />
        </div>
        <div className="">
          <StaticImage
            src="../images/certifications-logos/onncce-logo.png"
            alt="word onncce"
            className="logo"
            objectFit="contain"
          />
        </div>
        <div className="">
          <img src={sa} className="logo" alt="logo" />
        </div>
        <div className="">
          <img src={ul} className="logo" alt="logo" />
        </div>
        <div className="">
          <StaticImage
            src="../images/certifications-logos/tuv.png"
            alt="word tuv"
            className="logo"
            objectFit="contain"
          />
        </div>{" "}
      </div>
    </section>
  )
}
