import * as React from "react"

import Seo from "../components/seo"

import "../styles/main.styl"
import "../styles/colors.styl"

import Hero from "../components/Hero"
import WhyWhithUs from "../components/WhyWhithUs"
import PanelBenefits from "../components/PanelBenefits"
import Certifications from "../components/Certifications"
import Footer from "../components/Footer"
import CallToActionButton from "../components/CallToActionButton"

import { ParallaxProvider } from "react-scroll-parallax"

const IndexPage = () => (
  <>
    <ParallaxProvider>
      <Seo title="PHOTON Paneles Solares" />
      <Hero />
      <PanelBenefits />
      <CallToActionButton />
      <WhyWhithUs />
      <Certifications />
      <Footer />
    </ParallaxProvider>
  </>
)

export default IndexPage
