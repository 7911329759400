import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCalculator,
  faDonate,
  faGlobeAmericas,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons"

import "../styles/components/PanelBenefits.styl"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function PanelBenefits() {
  const blue = "#03588c"
  return (
    <div className="wrapper--center">
      <div className="benefits">
        <h2 className="benefits__main-text">
          Principales <strong className="bold--blue">beneficios </strong> que{" "}
          <strong className="bold--blue">obtienes </strong>
          al instalar nuestros paneles solares
        </h2>
        <AniLink
          className="b-button--white"
          paintDrip
          hex={blue}
          duration={0.5}
          to="/Paneles-solares"
        >
          <button className="benefits__button b-button">
            Ver clientes que ya están ahorrando
          </button>
        </AniLink>
        <div className="benefits__list">
          <div
            className="benefit benefit-1"
            data-sal="slide-right"
            data-sal-delay="100"
            data-sal-easing="ease"
          >
            <div className="benefit__icon">
              <FontAwesomeIcon icon={faUserShield} size="10x" />
            </div>
            <div className="benefit__title">
              25 Años de <strong className="bold--blue">garantía</strong>
            </div>
            <div className="benefit__desc">
              Ofrecemos 25 años de garantía. Nuestros paneles cuentan con más de{" "}
              <strong className="bold--blue">{" 40 años"}</strong> de vida útil.
            </div>
          </div>
          <div
            className="benefit benefit-2"
            data-sal="slide-right"
            data-sal-delay="100"
            data-sal-easing="ease"
          >
            <div className="benefit__icon">
              <FontAwesomeIcon icon={faDonate} size="10x" />
            </div>
            <div className="benefit__title">
              Ahorro de <br />
              <strong className="bold--blue">más del 95%</strong> de luz
            </div>
            <div className="benefit__desc">
              Una vez instalado tu Sistema Fotovoltaico de acuerdo a tus
              necesidades, tu factura de CFE será mucho más barata.
            </div>
          </div>
          <div
            className="benefit benefit-3"
            data-sal="slide-left"
            data-sal-delay="100"
            data-sal-easing="ease"
          >
            <div className="benefit__icon">
              <FontAwesomeIcon icon={faCalculator} size="10x" />
            </div>
            <div className="benefit__title">
              100% <strong className="bold--blue">deducible</strong> de
              impuestos
            </div>
            <div className="benefit__desc">
              Invertir en energía solar tiene un beneficio fiscal si eres una
              persona física o moral. Ya que los paneles solares son deducibles
              al 100%.
            </div>
          </div>
          <div
            className="benefit benefit-4"
            data-sal="slide-left"
            data-sal-delay="100"
            data-sal-easing="ease"
          >
            <div className="benefit__icon">
              <FontAwesomeIcon icon={faGlobeAmericas} size="10x" />
            </div>
            <div className="benefit__title">
              <strong className="bold--blue">Sustentabilidad</strong>
            </div>
            <div className="benefit__desc">
              La larga duracion y calidad de nuestros productos hace que sean
              una
              <strong className="bold--blue">
                {" inversión duradera y confiable. "}
              </strong>
              Además de que nuestros productos son Eco Sustentables.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
