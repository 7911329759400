import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import "../styles/components/CallToActionButton.styl"

export default function CallToActionButton() {
  return (
    <div className="call-to-action">
      <AniLink cover bg="#FFF" to="/contacto">
        <button className="call-to-action__button">Comenzar a ahorrar</button>
      </AniLink>
    </div>
  )
}
