import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { StaticImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "../styles/components/HeaderNobg.styl"

export default function HeaderNobg() {
  const [visible, setVisible] = useState("noVisible")
  const [rotatedBars, setRotatedBars] = useState("notRotated")

  function navBarRotation() {
    if (rotatedBars === "notRotated") {
      setRotatedBars("rotated")
    } else setRotatedBars("notRotated")
  }

  function navBarVisible() {
    if (visible === "visible") setVisible("noVisible")
    else setVisible("visible")
  }

  function handleNavbarNoBGOnClick() {
    navBarRotation()
    navBarVisible()
  }

  const color = "#FFF"

  return (
    <div className="HeaderNobg">
      <div className="Header-nobg__logo">
        <StaticImage
          src="../images/photon-white.png"
          alt="white circle and PHOTON letters"
          className="Header-nobg__image"
          objectFit="contain"
          placeholder="tracedSVG"
          tracedSVGOptions="threshold: 255"
        />
      </div>
      <div id="header-nobg__nav" className={`header-nobg__nav ` + visible}>
        <AniLink cover bg={color} duration={0.5} to="/Paneles-solares">
          Paneles solares
        </AniLink>
        <AniLink
          paintDrip
          hex={color}
          duration={0.5}
          to="/Calentadores-solares"
        >
          Calentadores Solares
        </AniLink>
        <AniLink cover bg={color} duration={0.5} to="/colectores-para-alberca">
          Colectores para Alberca
        </AniLink>
        <AniLink paintDrip hex={color} duration={0.5} to="/acerca-de-nosotros">
          Acerca de nosotros
        </AniLink>
      </div>
      <button
        className={`Header-nobg--bars ` + rotatedBars}
        onClick={handleNavbarNoBGOnClick}
      >
        <FontAwesomeIcon icon={faBars} size="2x" />
      </button>
    </div>
  )
}
