import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCommentDots,
  faGlobe,
  faHammer,
  faPencilRuler,
} from "@fortawesome/free-solid-svg-icons"

import "../styles/components/WhyWhithUs.styl"
import { Parallax } from "react-scroll-parallax"

export default function WhyWhithUs() {
  return (
    <div className="Why-with-us">
      <Parallax y={["100px", "0px"]}>
        <h2 className="us__title">¿Por qué con nosotros?</h2>
      </Parallax>
      <div className="reasons">
        <div className="reason__card us-reason__1">
          <div className="reason-box">
            <i className="reason-box__icon">
              <FontAwesomeIcon icon={faGlobe} size="2x" />
            </i>
            <p className="reason-box__desc">
              Podrás acceder a monitorear tu generación en tiempo real desde la
              app o sitio WEB.
            </p>
          </div>
        </div>
        <div className="reason__card us-reason__2">
          <div className="reason-box">
            <i className="reason-box__icon">
              <FontAwesomeIcon icon={faHammer} size="2x" />
            </i>
            <p className="reason-box__desc">
              - La mejor instalación que podrás encontrar. <br /> - Seguimos
              todas las regulaciones. <br /> - No tendras molestias con CFE.
            </p>
          </div>
        </div>
        <div className="reason__card us-reason__3">
          <div className="reason-box">
            <i className="reason-box__icon">
              <FontAwesomeIcon icon={faPencilRuler} size="2x" />
            </i>
            <p className="reason-box__desc">
              - Evaluación exacta de tus necesidades de paneles e instalación.
            </p>
          </div>
        </div>
        <div className="reason__card us-reason__4">
          <div className="reason-box">
            <i className="reason-box__icon">
              <FontAwesomeIcon icon={faCommentDots} size="2x" />
            </i>
            <p className="reason-box__desc">
              - Servicios post-venta. <br /> Incluso, te brindamos: <br />
              <div className="pl-2">
                - refacciones, <br />
                - reparaciones, <br />- mantenimiento, <br /> - limpieza. <br />{" "}
              </div>
              Cuando lo requieran tus equipos.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
