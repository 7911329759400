import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

import "../styles/components/MenuNavMobile.styl"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function MenuNavMobile() {
  const [open, setOpen] = useState("closed")
  const [rotatedBars, setRotatedBars] = useState("notRotated")

  function navBarRotation() {
    if (rotatedBars === "notRotated") {
      setRotatedBars("rotated")
    } else setRotatedBars("notRotated")
  }
  function navBarToggle() {
    if (open === "closed") setOpen("open")
    else setOpen("closed")
  }
  function handleNavbarNoBGOnClick() {
    navBarRotation()
    navBarToggle()
  }
  const blue = "#03588c"
  return (
    <div className={`MenuNavMobile ` + open}>
      <button
        className={`menu-nav-mobile__bars ` + rotatedBars}
        onClick={handleNavbarNoBGOnClick}
      >
        <FontAwesomeIcon icon={faBars} size="2x" />
      </button>
      <div className={`menu-nav-mobile__nav ` + open}>
        <AniLink cover bg={blue} duration={0.5} to="/Paneles-solares">
          Paneles solares
        </AniLink>
        <AniLink paintDrip hex={blue} duration={0.5} to="/Calentadores-solares">
          Calentadores Solares
        </AniLink>
        <AniLink cover bg={blue} duration={0.5} to="/colectores-para-alberca">
          Colectores para Alberca
        </AniLink>
        <AniLink paintDrip hex={blue} duration={0.5} to="/acerca-de-nosotros">
          Acerca de nosotros
        </AniLink>
      </div>
    </div>
  )
}
