import React from "react"

import HeaderNobg from "../components/HeaderNobg"

import "../styles/components/Hero.styl"
import { StaticImage } from "gatsby-plugin-image"
import MenuNavMobile from "./MenuNavMobile"
import { Link } from "gatsby"

export default function Hero() {
  return (
    <div className="Hero">
      <div className="overlay-hero"></div>
      <StaticImage
        className="Hero__background"
        src="../images/bg.png"
        alt="Solar panels on a valley"
        loading="lazy"
        placeholder="tracedSVG"
        quality="100"
      />
      <HeaderNobg />

      <div className="Hero-call-to-action">
        <p className="Hero-call-to-action__text">
          Ahorra invirtiendo en Energías Sustentables.
        </p>
        <Link to="#Footer" className="Hero-call-to-action__button-link">
          <button
            className="Hero-call-to-action__button-link__button"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
          >
            Comenzar a ahorrar
          </button>
        </Link>
      </div>
      <MenuNavMobile />
    </div>
  )
}
